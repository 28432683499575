body {
     
    overflow-x: hidden; /* Hide horizontal scrollbar */
}

.centerItem {
    text-align: center;
}

.wrapText {
    word-break:break-all;
}

.dotloader {
    margin-left: 10px;
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}

.colunmHeader {
    width:auto;
    padding-top:12px !important;
    padding-bottom:10px !important;
    padding-left:24px !important;
    padding-right:24px !important;
     
    opacity:0.7 !important;
    background:transparent !important;
    color:#7b809a !important;
    border-radius:none !important;
    box-shadow:none !important;
    font-size:0.65rem !important;
    font-weight:700 !important;
    border-bottom:0.0625rem solid #dee2e6 !important;
    text-transform: uppercase;
}

.tableCell {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 0.75rem !important;
    font-weight: 300 !important;
    line-height: 1.5 !important; 
    letter-spacing: 0.02857em  !important;  
    color: #7b809a  !important; 
    word-break:break-all !important; 
}